import {startpage as DPL} from '@s1/dpl'
import {getAllExtensionParams, getExtensionMetadataFromLocalStorage} from './extensionMetadata'
import {getAllAppParams} from './appMetadata'
import {IS_DEV} from '../env'

const {
    events: {startpage: spDpl},
} = DPL // export events

export const EVENTS = spDpl

const apiOrigin = IS_DEV ? 'https://www.startpage.com/sp' : '/sp'
const apiUrl = IS_DEV ? 'https://www.startpage.com/mktl' : '/mktl'

export const payloadAllowlist = [
    'act', // combined parameter for sgc and msact
    'account',
    'campaign',
    'date',
    'experiment',
    'group',
    'now',
    'pl',
    'referrer',
    'segment',
    'sgc',
    'source',
    'surveyReason',
    'surveyReasonText',
    'type',
    'url',
    'os',
    'browser',
    'country',
]

export const logDpl = async (event = EVENTS.StartpagePageLoad, payload = {}) => {
    const marketingParams = getAllExtensionParams() || {}
    const finalPayload = {
        ...marketingParams,
        ...payload, // it's important that this comes second so that payload k/v pairs can override marketingParams
    }

    return event.log(finalPayload, {
        apiOrigin,
        payloadAllowlist,
    })
}

export const logDplWithExtensionParams = async (
    event = EVENTS.StartpagePageLoad,
    payload = {},
    payloadDenylist = ['extSgc'],
) => {
    const {experiment} = payload
    const allExtensionParams = getAllExtensionParams()
    let defaultPayload
    if (experiment !== undefined) {
        defaultPayload = {
            ...allExtensionParams,
            experiment,
        }
    } else {
        defaultPayload = allExtensionParams
    }
    const extMeta = getExtensionMetadataFromLocalStorage()
    const finalPayload = extMeta
        ? {
              ...payload,
              ...extMeta,
          }
        : {
              ...payload,
              ...defaultPayload,
          }

    if (payloadDenylist.length) {
        for (const key of payloadDenylist) {
            delete finalPayload[key]
        }
    }
    return event.log(finalPayload, {
        apiOrigin,
        payloadAllowlist,
    })
}

export const logDplWithAppParams = async (
    event = EVENTS.StartpageAppPageLoad,
    payload = {},
    payloadDenylist = ['extSgc'],
) => {
    const payloadAllowlistApp = [
        'account',
        'act', // combined parameter for sgc and msact
        'adgroup',
        'campaign',
        'country',
        'date',
        'experiment',
        'group',
        'now',
        'os',
        'referrer',
        'sgc',
        'source',
        'surveyReason',
        'surveyReasonText',
        'type',
        'url',
        'variant', // same as 'experiment'
        'keyword',
        'gclid',
        'fbclid',
        'exoid',
        'tbid',
        'cid',
        'utmSource',
        's1aid',
        's1cid',
        's1agid',
        's1kid',
    ]
    const {experiment} = payload
    const allExtensionParams = getAllAppParams(experiment)
    let defaultPayload
    if (experiment !== undefined) {
        defaultPayload = {
            ...allExtensionParams,
            experiment,
        }
    } else {
        defaultPayload = allExtensionParams
    }
    const finalPayload = {
        ...payload,
        ...defaultPayload,
    }

    if (payloadDenylist.length) {
        payloadDenylist.forEach((key) => {
            delete finalPayload[key]
        })
    }
    return event.log(finalPayload, {
        apiUrl,
        payloadAllowlist: payloadAllowlistApp,
    })
}
