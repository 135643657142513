/* eslint-disable camelcase */
import axios from 'axios'
import {LOGGING_BASE_URL} from '../constants'
import {getUserAgent} from './userAgent'
import {getUrlParameterByName, isNewTabPage} from './url'
import {isCampaignSourceFrom} from './campaignSource'
import {Alert} from './alert'

export const defaultParams = {
    firefox: {
        pl: 'ext-ff',
        campaign: 'none',
        group: 'none',
        source: 'organic',
    },
    chrome: {
        pl: 'ext-chrome',
        campaign: 'none',
        group: 'none',
        source: 'organic',
    },
    edge: {
        pl: 'ext-edge',
        campaign: 'none',
        group: 'none',
        source: 'organic',
    },
    unsupported: {
        pl: 'unsupported',
        campaign: 'none',
        group: 'none',
        source: 'organic',
    },
}

export const getCurrentDate = () => {
    // en-CA returns date in format YYYY-MM-DD
    // timeZone: America/Los_Angeles is to ensure the date is in the desired timezone (PST)
    // which makes it easier for reporting since the google subid data comes back in PST
    return new Date().toLocaleDateString('en-CA', {
        timeZone: 'America/Los_Angeles',
    })
}

export const isValidDate = (dateStr) => {
    const regEx = /^\d{4}-\d{2}-\d{2}$/
    if (!dateStr.match(regEx)) return false // Invalid format
    const d = new Date(dateStr)
    const dNum = d.getTime()
    if (!dNum && dNum !== 0) return false // NaN value, Invalid date
    return d.toISOString().slice(0, 10) === dateStr
}

export const isValidCampaign = (campaignId, act) => {
    // campaign is made of only decimal values
    const isValidCampaignId = /^[\d]+$/g.test(campaignId)
    // act has no template string brackets: {}
    const isValidact = !/[{}]+/g.test(act)
    return isValidCampaignId && isValidact
}

export const getPlValue = () => {
    const ua = getUserAgent()
    const config = defaultParams[ua]
    if (config && config.pl) {
        return config.pl
    }
    return null
}

export const parsedCampaignFromUrl = () => {
    const ua = getUserAgent()
    const campaign = getUrlParameterByName('campaign')
    const defaultCampaign = defaultParams[ua] && defaultParams[ua].campaign
    if (!campaign) {
        return {campaign: defaultCampaign}
    }
    const parsed = campaign.split('_')
    const campaignId = parsed[0]
    let act // click_id (renamed for anonymity)
    let sgc // gclid (renamed for anonymity)
    act = sgc = parsed.slice(1).join('_') || undefined
    if (!isValidCampaign(campaignId, act)) {
        new Alert().sendOnce({campaign})
        return {campaign: defaultCampaign}
    }
    const isCampaignFromGoogle =
        isCampaignSourceFrom('google_search_network') || isCampaignSourceFrom('google_display_network')
    if (isCampaignFromGoogle) {
        return {
            campaign: campaignId,
            // conditionally add act/sgc if it is not undefined
            ...(act && {act}), // click_id (renamed for anonymity)
            ...(sgc && {sgc}), // gclid (renamed for anonymity)
        }
    }
    return {
        campaign: campaignId,
        // conditionally add act if it is not undefined
        ...(act && {act}), // click_id (renamed for anonymity)
    }
}

export const logSgc = async () => {
    const campaignParts = parsedCampaignFromUrl()
    const {sgc} = campaignParts
    if (!sgc) return
    try {
        const resp = await axios.post(`${LOGGING_BASE_URL}/sp/sgc`, {sgc})
        const {
            data: {short_sgc, expdate},
        } = resp
        const extMeta = localStorage.getItem('extMeta')
        if (extMeta) {
            const parsedExtMeta = JSON.parse(extMeta)
            const newExtMeta = {
                ...parsedExtMeta,
                ...{
                    extSgc: {
                        short_sgc,
                        expdate: expdate + 86400, // add a day to this epoch timestamp to prevent time drift
                    },
                },
            }
            localStorage.setItem('extMeta', JSON.stringify(newExtMeta))
        }
    } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
    }
}

export const getAllExtensionParams = () => {
    const ua = getUserAgent()
    const keys = ['date', 'pl', 'source', 'segment', 'group', 'account']
    const params = keys.reduce((acc, key) => {
        if (key === 'date') {
            const date = getUrlParameterByName(key)
            // if date is not in the url, or if it's set in the url, but is not valid date, set it to today
            if (!date || !isValidDate(date)) {
                acc[key] = getCurrentDate()
                return acc
            }
        }
        const defaultVal = defaultParams[ua] && defaultParams[ua][key]
        const param = getUrlParameterByName(key) || defaultVal
        if (!(param === undefined) && !(param === null)) {
            acc[key] = param
        }
        return acc
    }, {})
    const campaignParams = parsedCampaignFromUrl()
    const allExtensionParams = {
        ...params,
        ...campaignParams,
    }
    if (isNewTabPage()) {
        // hard code segment on newtab extension page
        return {
            ...allExtensionParams,
            segment: 'startpage.tabext',
        }
    }
    return allExtensionParams
}

export const setExtensionParamsToLocalStorage = (experiment) => {
    return new Promise((resolve) => {
        localStorage.clear()
        const extensionParams = getAllExtensionParams()
        const extMeta = {...extensionParams, experiment}
        localStorage.setItem('extMeta', JSON.stringify(extMeta))
        resolve()
    })
}

export const getExtensionMetadataFromLocalStorage = () => {
    const extMeta = localStorage.getItem('extMeta')
    if (extMeta) {
        return JSON.parse(extMeta)
    }
    return null
}

export const clearLocalStorage = () => {
    const INTERVAL = 500
    const MAX_TRIES = 30
    const pollExtensionParam = (interval, numTries) => {
        if (localStorage.getItem('extLoaded')) {
            localStorage.clear()
            clearInterval(checkExtensionLoad)
        } else if (numTries >= MAX_TRIES) {
            clearInterval(checkExtensionLoad)
        } else {
            setTimeout(() => {
                const newTries = numTries + 1
                pollExtensionParam(interval, newTries)
            }, INTERVAL)
        }
    }
    const checkExtensionLoad = setTimeout(() => {
        pollExtensionParam(checkExtensionLoad, 0)
    }, INTERVAL)
}
